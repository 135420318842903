.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.section-title {
  margin-bottom: 60px;
}
.section-title p {
  color: #777;
  font-size: 16px;
}
.section-title h4 {
  text-transform: capitalize;
  font-size: 40px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.section-title h4:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: var(--accent-color);
  bottom: 0;
  left: 50%;
  margin-left: -30px;
}
.section-title h4:after {
  position: absolute;
  background-color: var(--accent-color);
  content: "";
  width: 10px;
  height: 10px;
  bottom: -4px;
  left: 50%;
  margin-left: -5px;
  border-radius: 50%;
}
ul.timeline-list {
  position: relative;
  margin: 0;
  padding: 0;
}
ul.timeline-list:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--accent-color);
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
ul.timeline-list li {
  position: relative;
  clear: both;
  display: table;
}
ul.timeline-list li .timeline_content {
  width: 45%;
  color: var(--primary-color);
  padding: 30px;
  float: left;
  text-align: right;
}
ul.timeline-list li:nth-child(2n) .timeline_content {
  float: right;
  text-align: left;
}
.timeline_content h4 {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0;
}
ul .imp-list:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--accent-color);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.timeline_content span {
  font-size: 18px;
  font-weight: 500;
  font-family: poppins;
  color: var(--accent-color);
}
