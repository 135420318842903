/* SocialBar.css */
.social-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  z-index: 999999;
}

.social-bar a {
  text-decoration: none;
  color: #000000; /* Icon color */
  margin: 2px 0;
  font-size: 20px; /* Adjust icon size as needed */
}
/* Add a vertical line under the icons */
.social-bar::after {
  content: "";
  display: block;
  width: 1px;
  height: 100px;
  margin: 0 auto;
  background-color: var(--primary-color);
}
